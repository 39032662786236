import PageContent from "../components/PageContent";
import i18n from "../i18n";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import {AxiosResponse} from "axios";
import DcCertificateAPI from "../api/DcCertificate";
import {BeatLoader} from "react-spinners";
import {TfiInfoAlt} from "react-icons/tfi";
import Text from "../components/Text";
import { formatDate } from "../utils/date"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styled from "@emotion/styled";
import StatusAction from "../components/StatusAction";
import CertificateStatus from "../enums/CertificateStatus";

const StyledTh = styled(Th)`
    padding: 8px;
    border: 1px solid #000000;
    background-color: #2A5595;
    color: #ffffff;
`

const StyledTd = styled(Td)`
    padding: 8px;
    border: 1px solid #000000;
`

const CertificateListPage = () => {
  const queryCertificateList : UseQueryResult<AxiosResponse<Array<ICertificateResponse>>> = useQuery({
    queryKey: ['certificateList'],
    queryFn: async () => {
      return await DcCertificateAPI.v1.list()
    }
  })

  return (
    <PageContent>
      <header>
        <h2>{i18n.ptBr.Pages.CertificateListPage.title}</h2>
        <h4>{i18n.ptBr.Pages.CertificateListPage.description}</h4>
      </header>

      {
        queryCertificateList.error && (<Text className={"error bg-error"}>
          <span><TfiInfoAlt /><span>{i18n.ptBr.ErrorMessage.serverError}</span></span>
        </Text>)
      }

      {
        queryCertificateList.isLoading && (
          <BeatLoader color="#8d8d8d" loading size={24}  />
        )
      }

      {
        queryCertificateList.isSuccess && (
          (
            <div style={{overflowX: "auto", width: "100%"}}>
              <Table>
                <Thead>
                <Tr>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnProtocol}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnProduct}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnStatus}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnExpireDate}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnAction}</StyledTh>
                </Tr>
                </Thead>
                <Tbody>
                {
                  queryCertificateList.data?.data.map((certificate:ICertificateResponse) => (
                    <Tr key={certificate?.protocol}>
                      <StyledTd><strong>{certificate?.protocol}</strong></StyledTd>
                      <StyledTd>{certificate?.product}</StyledTd>
                      <StyledTd>{certificate?.status.description}</StyledTd>
                      <StyledTd>
                        {certificate?.finalExpirationDate ? formatDate(certificate?.finalExpirationDate) : i18n.ptBr.ErrorMessage.undefinedData}
                      </StyledTd>
                      <StyledTd>
                        <StatusAction
                          className={"btn-decoration-underline text text-secondary"}
                          elementType={"link"}
                          onClick={undefined}
                          hasPermission={certificate.status.id === CertificateStatus.ISSUED}
                          linkTo={`/certificates/${certificate?.protocol}/revoke`}
                          buttonType={undefined}
                        >
                          {i18n.ptBr.Components.Buttons.revoke}
                        </StatusAction>
                      </StyledTd>
                    </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </div>
          )
        )
      }
    </PageContent>
  )
}

export default CertificateListPage