import PageContent from "../components/PageContent";
import Alert from "../components/Alert";
import Form from "../components/Form";
import Button from "../components/Button";
import {SubmitHandler, useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {useCallback, useState} from "react";
import DcCertificateAPI from "../api/DcCertificate";
import Text from "../components/Text";
import TextField from "../components/TextField";
import {TfiAlert, TfiInfoAlt} from "react-icons/tfi";
import {BeatLoader} from "react-spinners";
import i18n from "../i18n";
import IPersonAuthenticateRequest from "../interfaces/IPersonAuthenticateRequest";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const AuthenticationPage = () => {
  const [mainErrorMessage, setMainErrorMessage] = useState(i18n.ptBr.ErrorMessage.serverError)
  const { register, handleSubmit , formState: { errors: formErrors }} = useForm<any>()
  const [hasErros, setHasErrors] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha('login');
  }, [executeRecaptcha]);

  const mutationAuthenticate = useMutation({
    mutationKey: ['authenticate'],
    mutationFn: async (data: any) => {
      return await DcCertificateAPI.v1.authenticate(data)
    },
    onMutate: () => {
      setSpinner(true)
    },
    onSuccess: (response: any) => {
      console.log("mutationAuthenticate onSuccess: ", response)

      localStorage.setItem("token", response.data.token)

      setHasErrors(false)
      setSpinner(false)

      window.location.replace("/certificates")
    },
    onError: (error: any) => {
      console.error("mutationAuthenticate onError: ", error)

      setHasErrors(true)
      setSpinner(false)

      if (error.response.status === 401) setMainErrorMessage(i18n.ptBr.ErrorMessage.authenticatePersonError)
      else if (error.response.status === 404) setMainErrorMessage(i18n.ptBr.ErrorMessage.certificateNotFound)
      else if (error.response.status >= 500) setMainErrorMessage(i18n.ptBr.ErrorMessage.serverError)
    }
  })

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const recaptchToken = await handleReCaptchaVerify()

    if (recaptchToken) {
      const personAuthenticateRequest: IPersonAuthenticateRequest = {
        password: data.password,
        recaptchaToken: recaptchToken
      }

      const CPF_SIZE = 11;
      const CNPJ_SIZE = 14;
      const PROTOCOL_SIZE_MIN = 10;
      const PROTOCOL_SIZE_MAX = 15;

      switch (data.identifier.length) {
        case CNPJ_SIZE:
          Object.assign(personAuthenticateRequest, {cnpj: data.identifier})
          break;
        case CPF_SIZE:
          Object.assign(personAuthenticateRequest, {cpf: data.identifier})
          break;
        case PROTOCOL_SIZE_MIN:
        case PROTOCOL_SIZE_MAX:
          Object.assign(personAuthenticateRequest, {protocol: data.identifier})
          break;
      }

      mutationAuthenticate.mutate(personAuthenticateRequest)
    } else {
      console.error("ReCaptcha invalid user.")
    }
  }

  return (
    <PageContent>
      <header>
        <h2>{i18n.ptBr.Pages.AuthenticationPage.title}</h2>

        <Alert className={"alert-primary"}>
          <TfiAlert />
          <div>
            <h3>{i18n.ptBr.Pages.AuthenticationPage.alert.title}</h3>
            <p>{i18n.ptBr.Pages.AuthenticationPage.alert.description}</p>
          </div>
        </Alert>
      </header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div>
            <label>{i18n.ptBr.Pages.AuthenticationPage.form.fields.identifier.label}</label>
            <TextField
              type={"number"}
              error={Boolean(formErrors.identifier)}
              {...register("identifier", { required: true, minLength: 10, maxLength: 15 })}
            />
          </div>

          <div>
            <label>{i18n.ptBr.Pages.AuthenticationPage.form.fields.password.label}</label>
            <TextField
              error={Boolean(formErrors.password)}
              type="password" {...register("password", { required: true })}
            />
            <p>{i18n.ptBr.Pages.AuthenticationPage.form.fields.password.description}</p>
          </div>
        </fieldset>

        {(formErrors.password || formErrors.identifier || hasErros) && (
          <Text className={"error bg-error"}>
            {formErrors.identifier && (<span><TfiInfoAlt /><span>{i18n.ptBr.ErrorMessage.missingProtocolOrCpfOrCnpjError}</span></span>)}
            {formErrors.password && (<span><TfiInfoAlt /><span>{i18n.ptBr.ErrorMessage.missingPasswordError}</span></span>)}
            {hasErros && (<span><TfiInfoAlt /><span>{mainErrorMessage}</span></span>)}
          </Text>
        )}

        <footer>
          <Button className={"btn btn-primary"} type={"submit"}>
            {
              spinner ? (
                <BeatLoader color="#ffffff" loading={spinner} size={10}/>
              ) : (i18n.ptBr.Components.Buttons.continue)
            }
          </Button>
        </footer>
      </Form>
    </PageContent>
  )
}

export default AuthenticationPage