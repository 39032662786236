import {PrivateRoute} from "../components/PrivateRoute";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import AuthenticationPage from "../pages/AuthenticationPage";
import CertificateListPage from "../pages/CertificateListPage";
import RevokeCertificatePage from "../pages/RevokeCertificatePage";
import RevokedCertificatePage from "../pages/RevokedCertificatePage";
import Header from "../components/Header";
import Content from "../components/Content";
import HomePage from "../pages/HomePage";

const Routes = () => {
    const publicRoutes = [
        {
            path: "*",
            element: <HomePage />,
        },
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/login",
            element: <AuthenticationPage />,
        }
    ];

    const privateRoutes = [
        {
            path: "/certificates",
            element: <PrivateRoute />,
            children: [
                {
                    path: "*",
                    element: <CertificateListPage />,
                },
                {
                    path: "/certificates",
                    element: <CertificateListPage />,
                },
                {
                    path: "/certificates/:protocol/revoke",
                    element: <RevokeCertificatePage />,
                },
                {
                    path: "/certificates/:protocol/revoked",
                    element: <RevokedCertificatePage />,
                }
            ],
        },
    ];

    const router = createBrowserRouter([
        ...publicRoutes,
        ...privateRoutes,
    ]);

    return (
      <>
          <Header />

          <Content>
              <RouterProvider router={router} />
          </Content>
      </>
    );
}

export default Routes;