import {AxiosResponse} from "axios";
import IAuthenticateRequest from "../../interfaces/IPersonAuthenticateRequest";
import IAuthenticateResponse from "../../interfaces/IPersonAuthenticateResponse";
import ICertificateResponse from "../../interfaces/ICertificateResponse";
import IReasonType from "../../interfaces/IReasonType";
import ISubReasonType from "../../interfaces/ISubReasonType";
import IRevokeCertificateRequest from "../../interfaces/IRevokeCertificateRequest";
import dcCertificateAPI from "./config";

const authenticate = async (requestData: IAuthenticateRequest): Promise<AxiosResponse<IAuthenticateResponse>> =>
  dcCertificateAPI.post(`/v1/serasa/certificate/authenticate?g-recaptcha-response=${requestData.recaptchaToken}`, requestData)

const list = async (): Promise<AxiosResponse<Array<ICertificateResponse>>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/list`)

const certificate = async (protocol: any): Promise<AxiosResponse<ICertificateResponse>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate?protocol=${protocol}`)

const reasonList = async (certificateType: any): Promise<AxiosResponse<Array<IReasonType>>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/reason/list?certificateType=${certificateType}`)

const subReasonList = async (reasonType: any): Promise<AxiosResponse<Array<ISubReasonType>>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/sub-reason/list?reasonType=${reasonType}`)

const revoke = async (requestData: IRevokeCertificateRequest) =>
  dcCertificateAPI.put(`/v1/serasa/certificate/revoke`, requestData)

const Endpoints = {
  v1: {
    authenticate,
    revoke,
    list,
    certificate,
    reasonList,
    subReasonList,
  }
}

export default Endpoints