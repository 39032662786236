import styled from "@emotion/styled";

const Alert = styled.div`
    color: #ffffff;
    display: flex;
    padding: 16px 24px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;

    @media (max-width:480px)  {
        flex-direction: column;
        row-gap: 16px;
    }
    
    svg {
        font-size: 64px;
        margin: 0 16px;
    }
    
    &.alert-primary {
        background-color: #0A6CB5;
    }
`

export default Alert