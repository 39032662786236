import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";

const Link = styled(NavLink)`
    padding: 16px 32px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
    color: #000000;
    transition: 0.3s;
    
    &:hover {
        color: rgba(0, 0, 0, 0.6);
    }

    &.btn-primary {
        background-color: #2f56b3;
        color: #ffffff;

        &:hover {
            background-color: rgba(42, 85, 149, 0.6);
        }
    }

    &.btn-secondary {
        background-color: #e40781;
        color: #ffffff;
        
        &:hover {
            background-color: rgba(228, 7, 129, 0.6);
        }
    }
    
    &.btn-whatsapp {
        background-color: #25d366;
        color: #ffffff;
        
        &:hover {
            background-color: rgba(37, 211, 102, 0.6);
        }
    }
    
    &.btn-decoration-underline {
        text-decoration: underline;
    }
    
    &.text-secondary {
        color: #e40781;

        &:hover {
            color: rgba(228, 7, 129, 0.6);
        }
    }
    
    &.text {
        padding: 0;
        text-align: left;
    }
`

export default Link