import PageContent from "../components/PageContent";
import i18n from "../i18n";
import Alert from "../components/Alert";
import {TfiAlert} from "react-icons/tfi";
import Link from "../components/Link";
import FaqContainer from "../components/FaqContainer";
import FaqSection from "../components/FaqSection";
import ChannelContainer from "../components/ChannelContainer";
import ChannelBox from "../components/ChannelBox";
import {FaWhatsapp} from "react-icons/fa";

const HomePage = () => {
  return (
    <PageContent>
      <header>
        <h2>{i18n.ptBr.Pages.HomePage.title}</h2>
      </header>

      <FaqContainer>
        {i18n.ptBr.Pages.HomePage.faq.map((faq, index) => (
          <FaqSection key={index}>
            <h4>{faq.title}</h4>
            <ul>
              {faq.answers.map((answer, index) => (
                <li>{answer}</li>
              ))}
            </ul>
          </FaqSection>
        ))}
      </FaqContainer>

      <Alert className={"alert-primary"}>
        <TfiAlert />
        <div>
          <h3>{i18n.ptBr.Pages.HomePage.alert.title}</h3>
          <p>{i18n.ptBr.Pages.HomePage.alert.description}</p>
        </div>
      </Alert>

      <ChannelContainer>
        <ChannelBox>
          <div>
            <p><strong>{i18n.ptBr.Pages.HomePage.channel.phone.title}</strong></p>
            <p>{i18n.ptBr.Pages.HomePage.channel.phone.description}</p>
          </div>
          <div>
            <Link className={"btn-whatsapp"}
                  to={i18n.ptBr.Pages.HomePage.channel.phone.whatsapp}
                  target={"_blank"} rel={"noopener noreferrer"}
            >
              <FaWhatsapp />
              <span>{i18n.ptBr.Components.Buttons.whatsapp}</span>
            </Link>
          </div>
        </ChannelBox>

        <ChannelBox>
          <div>
            <p><strong>{i18n.ptBr.Pages.HomePage.channel.internet.title}</strong></p>
            <p>{i18n.ptBr.Pages.HomePage.channel.internet.description}</p>
          </div>
          <div>
            <Link className={"btn-secondary"} to={`/login`}>{i18n.ptBr.Components.Buttons.revokeOnline}</Link>
          </div>
        </ChannelBox>
      </ChannelContainer>
    </PageContent>
  );
}

export default HomePage;