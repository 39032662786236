import Link from "./Link";
import Button from "./Button";

// @ts-ignore
const StatusAction = ({ elementType, hasPermission, className, linkTo, buttonType, onClick, children }) => {
  if (elementType === 'button') {
    return (
      <Button className={`${className} ${!hasPermission ? 'disabled-button' : ''}`} disabled={!hasPermission} type={buttonType} onClick={onClick}>
        {children}
      </Button>
    )
  } else if (elementType === 'link') {
    return (
      <Link className={`${className} ${!hasPermission ? 'disabled-link' : ''}`} to={hasPermission ? linkTo : ""}>{children}</Link>
    )
  } else {
    return null;
  }
}

export default StatusAction;