import styled from "@emotion/styled";

const ChannelContainer = styled.section`
    display: flex;
    flex-direction: row;

    @media (max-width:480px)  {
        flex-direction: column;
    }
`

export default ChannelContainer