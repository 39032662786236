import styled from "@emotion/styled";

const FaqSection = styled.section`
    margin-bottom: 36px;
    
    &:last-child {
        margin-bottom: 0;
    }
    
    h4 {
        font-size: 22px;
        margin-bottom: 16px;
    }

    ul {
        list-style: inside;
        padding-left: 16px;
        
        li {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`

export default FaqSection;