const ErrorMessage = {
  serverError: "Falha na comunicação com o servidor.",
  authenticatePersonError: "Falha na autenticação. Os dados informados estão incorretos. Protocolo ou CPF ou CNPJ ou Senha inválida.",
  missingProtocolOrCpfOrCnpjError: "Você precisa informar o Protocolo ou CPF ou CNPJ.",
  missingPasswordError: "Você precisa informar a senha de revogação.",
  missingReasonError: "Você precisa informar o motivo da revogação.",
  missingSubReasonError: "Você precisa informar o sub-motivo da revogação.",
  certificateNotFound: "Nenhum certificado foi encontrado.",
  undefinedData: "Data indefinida",
}

const Buttons = {
  cancel: "Cancelar",
  revoke: "Revogar",
  continue: "Continuar",
  select: "Selecionar",
  backToCertificatesList: "Voltar para a listagem de Certificados",
  revokeOnline: "Revogação on-line",
  whatsapp: "Whatsapp"
}

const Modal = {
  title: "Tem a certeza?",
  description: "Se revogar o certificado não será mais válido."
}

const HomePage = {
  title: "Revogação de Certificado Digital",
  faq: [
    {
      title: "Quando cancelar / revogar seu Certificado Digital?",
      answers: [
        "Em caso de alteração de qualquer informação do Certificado (exemplos: RG, e-mail ou nome);",
        "Caso seja constatada informação incorreta no Certificado",
        "No caso de perda, roubo, modificação, acesso indevido, comprometimento ou suspeita de comprometimento da chave privada correspondente ou da sua mídia armazenadora (Cartão Inteligente ou Token USB)",
      ]
    },
    {
      title: "Quem pode solicitar uma revogação?",
      answers: [
        "Titular do Certificado Digital;",
        "Responsável pelo Certificado Digital, no caso de equipamentos, aplicações e pessoas jurídicas;",
        "Empresa ou Órgão, quando o titular do Certificado Digital fornecido por esta entidade for seu empregado, funcionário ou servidor."
      ]
    },
    {
      title: "Como cancelar / revogar",
      answers: [
        "Para cancelar/revogar seu Certificado Digital, escolha uma das formas abaixo:"
      ]
    }
  ],
  alert: {
    title: "Tem certeza que deseja revogar o seu certificado?",
    description: "A revogação é uma ação definitiva e inutilizará de forma permanente o seu Certificado Digital."
  },
  channel: {
    phone: {
      title: "FALE CONOSCO",
      description: "Paramos de comercializar o Certificado Digital em Fevereiro de 2024. Mas não se preocupe, você terá todo o suporte necessário por aqui mesmo.",
      whatsapp: "https://wa.me/5511943865014"
    },
    internet: {
      title: "PELA INTERNET",
      description: "Para fazer o cancelamento/revogação on-line, basta ter em mãos o número do protocolo e senha de revogação."
    }
  }
}

const AuthenticationPage = {
  title: "Revogação de Certificado Digital",
  alert: {
    title: "Tem certeza que deseja revogar o seu certificado?",
    description: "A revogação é uma ação definitiva e inutilizará de forma permanente o seu Certificado Digital."
  },
  form: {
    fields: {
      identifier: {
        label: "Protocolo ou CPF ou CNPJ"
      },
      password: {
        label: "Senha de Revogação",
        description: "Cadastrada no momento da emissão do certificado."
      }
    }
  }
}

const CertificateListPage = {
  title: "Certificados",
  description: "Selecione o certificado que deseja revogar.",
  table: {
    thead: {
      columnProtocol: "Protocolo",
      columnProduct: "Produto",
      columnStatus: "Status",
      columnExpireDate: "Validade",
      columnAction: "Ação"
    }
  }
}

const CertificatePage = {
  title: "Solicitação de revogação",
  table: {
    thead: {
      columnProtocol: "Protocolo",
      columnProduct: "Produto",
      columnStatus: "Status",
      columnExpireDate: "Validade"
    }
  },
  form: {
    fields: {
      reason: {
        label: "Motivo da revogação"
      },
      subReason: {
        label: "Sub-motivo da revogação",
        warningText: "Selecione um motivo para listar os sub-motivos."
      }
    }
  }
}

const RevokedCertificatePage = {
  revokedTitle: "Certificado Revogado com Sucesso",
  revokeTitle: "Certificado não Revogado",
  revokedDescription: "A confirmação da revogação será encaminhada ao e-mail cadastrado no Certificado Digital.",
  revokeDescription: "Esse Certificado Digital ainda não foi revogado, realize o fluxo de revogação."
}

const ptBR = {
  Pages: {
    HomePage,
    AuthenticationPage,
    CertificateListPage,
    CertificatePage,
    RevokedCertificatePage
  },
  Components: {
    Buttons,
    Modal
  },
  ErrorMessage
}

export default ptBR
