import styled from "@emotion/styled";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 40px;

    fieldset {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        div {
            width: 45%;
        }
        
        p {
            margin-top: 16px;
        }

        @media (max-width:480px)  {
            flex-direction: column;
            row-gap: 40px;
            
            div {
                width: 100%;
            }
        }
    }
    
    input, select {
        width: 100%;
        min-height: 54px;
        border: 2px solid #D1D1D1;
        border-radius: 8px;
        padding: 24px;
        font-size: 16px;
        transition: 0.3s;

        @media (max-width:480px)  {
            font-size: 16px;
        }
    }

    input {
        color: #1B1B1B;
    }

    select {
        color: #1B1B1B;
        background-color: #ffffff;
    }

    label {
        font-size: 18px;
        font-weight: bold;
        color: #1B1B1B;
        margin-bottom: 16px;
        display: block;
    }

    p {
        font-size: 16px;
        color: #1B1B1B;
        text-align: right;

        @media (max-width:480px)  {
            font-size: 14px;
            text-align: center;
        }
    }

    footer {
        text-align: right;
    }
`

export default Form