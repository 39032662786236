import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AuthProvider from "./providers/AuthProvider";
import Routes from "./providers/RouteProvider";
import './style.css'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
              <Routes />
            </GoogleReCaptchaProvider>
          </AuthProvider>
      </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
