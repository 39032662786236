import Button from "./Button";
import ModalContent from "./ModalContent";
import {TfiHelpAlt, TfiInfoAlt} from "react-icons/tfi";
import {BeatLoader} from "react-spinners";
import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import DcCertificateAPI from "../api/DcCertificate";
import Text from "./Text";
import i18n from "../i18n";
import {useParams} from "react-router-dom";
import StatusAction from "./StatusAction";
import CertificateStatus from "../enums/CertificateStatus";

// @ts-ignore
const ModalToRevoke = (props) => {
  let { protocol } = useParams();
  const [mainErrorMessage, setMainErrorMessage] = useState(i18n.ptBr.ErrorMessage.serverError)
  const [hasErros, setHasErrors] = useState(false)
  const [spinner, setSpinner] = useState(false);

  const muationRevokeCertificate = useMutation({
    mutationKey: ['revokeCertificate'],
    mutationFn: async () => {
      const formData = props.formData();

      const revokeRequest = {
        reason: parseInt(formData.reason),
        subReason: parseInt(formData.subReason),
        protocol,
      }

      // @ts-ignore
      return await DcCertificateAPI.v1.revoke(revokeRequest)
    },
    onMutate: () => {
      setSpinner(true)
    },
    onSuccess: (response: any) => {
      console.log("muationRevokeCertificate onSuccess: ", response)

      setHasErrors(false)
      setSpinner(false)

      window.location.replace(`/certificates/${protocol}/revoked`)
    },
    onError: (error: any) => {
      console.error("muationRevokeCertificate onError: ", error)

      setHasErrors(true)
      setSpinner(false)

      if (error.response.status >= 400) setMainErrorMessage(i18n.ptBr.ErrorMessage.serverError)
    }
  })

  return (
    // @ts-ignore
    <ModalContent open={props.open}>
      <section>
        <TfiHelpAlt />

        <h3>{i18n.ptBr.Components.Modal.title}</h3>

        <p>{i18n.ptBr.Components.Modal.description}</p>

        <footer>
          <Button onClick={props.onCancel} className={"btn-disabled"} type={"button"}>{i18n.ptBr.Components.Buttons.cancel}</Button>

          <StatusAction
            className={"btn-primary"}
            elementType={"button"} onClick={() => { muationRevokeCertificate.mutate();}}
            hasPermission={props.statusId === CertificateStatus.ISSUED}
            linkTo={undefined}
            buttonType={"button"}
          >
            {
              spinner ? (
                <BeatLoader color="#ffffff" loading={spinner} size={10}  />
              ) : (i18n.ptBr.Components.Buttons.revoke)
            }
          </StatusAction>
        </footer>

        {hasErros && (
          <Text className={"error bg-error"}>
            {hasErros && (<span><TfiInfoAlt /><span>{mainErrorMessage}</span></span>)}
          </Text>
        )}
      </section>
    </ModalContent>
  )
}

export default ModalToRevoke