import styled from "@emotion/styled";

const PageContent = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    
    header {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        text-align: center;
        width: 100%;
        
        p {
            font-size: 18px;
            margin-top: 8px;
        }
    }
`

export default PageContent