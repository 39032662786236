import styled from "@emotion/styled";

const ChannelBox = styled.section`
    background-color: #E6E6E6;
    width: 50%;
    border-radius: 10px;
    padding: 40px;
    margin-right: 16px;

    @media (max-width:480px)  {
        width: 100%;
        margin-bottom: 40px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    &:last-child {
        margin-right: 0;
    }
    
    p {
        margin-bottom: 4px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    i {
        font-style: italic;
        font-size: 14px;
    }
    
    div {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        
        &:last-child {
            text-align: center;
        }
        
        a {
            font-weight: bold;
            
            svg {
                margin-right: 8px;
            }
        }
    }
`

export default ChannelBox